<template>
    <el-dialog 
        title="실시간 단속 상태"
        :visible.sync="liveDetectDialog"
        :close-on-click-modal="false"
        append-to-body
        width="75%"
        top="5vh"
        class="el-dialog-aside"
        @open="openDialog"
    >
        <el-container>
            <el-container>
                <el-main class="detect-canvas-wrap" ref="detectCanvasWrap">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            xmlns:xlink="http://www.w3.org/1999/xlink" 
                            stroke="none" 
                            fill="none" 
                            overflow="hidden" 
                            preserveAspectRatio="none" 
                            class="detect-canvas"
                            :width="detectCanvasWidth"
                            :height="detectCanvasHeight"
                            :viewBox="detectCanvasViewBox"
                        >
                            <g>
                                <image 
                                    v-if="nowPresetDetectData.progressImageInfo.length > 0"
                                    :href="nowPresetDetectData.progressImageInfo[0].snapshotImageUrl"
                                    :width="detectCanvasWidth"
                                    :height="detectCanvasHeight"
                                ></image>
                            </g>
                            <!-- <detect-box
                                v-for="(data, index) in detectData"
                                :key="index"
                                :rect="data.carRect"
                                :ratio="ratio"
                                :detectData="data"
                                class="detect-box"
                            >
                            </detect-box> -->
                            <detect-box
                                v-if="nowPresetDetectData.carRect"
                                :rect="nowPresetDetectData.carRect"
                                :ratio="ratio"
                                :detectData="nowPresetDetectData"
                                class="detect-box"
                            >
                            </detect-box>
                        </svg>
                    </div>
                </el-main>
            </el-container>
            <el-aside class="info-panel" style="position:relative;">
              
                <div>
                    
                    <h4 style="margin:10px 0 0 15px;padding:0;height:22px">{{fcltData.fcltName}}</h4>

                    <h5  style="margin:5px 10px 0 0;padding:0;height:22px; text-align:right;">단속건수: {{detectData.length}}</h5>
                </div>
                    <!-- <div class="dk-card-dark">
                        <el-row>
                            <el-col :span="24">
                                <img :src="selectedDetectData.progressImageInfo[0].enforceImageInfo[1].enforceImageUrl" style="width:100%; height:auto;">
                            </el-col>
                        </el-row>
                    </div> -->
                  <div class="detect-data-wrap">
                    <el-table
                        :data="detectData"
                        empty-text="단속 정보가 없습니다."
                        size="mini"
                        @row-click="selectRow"
                        stripe
                    >
                        <el-table-column
                            prop="carNum"
                            label="차량번호"                                    
                        >
                        </el-table-column>
                        <el-table-column                                
                            label="경과시간"
                        >
                            <template slot-scope="scope">
                                {{getLeadTime(scope.row)}}
                            </template>
                        </el-table-column>
                        <el-table-column                                
                            label="상태"                                    
                        >
                            <template slot-scope="scope">
                                <span v-if="scope.row.detectProgressCode=='1'">초도</span>
                                <span v-else-if="scope.row.detectProgressCode=='3'">확정</span>
                                <span v-else-if="scope.row.detectProgressCode=='99'">실패</span>                                    
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-aside>
        </el-container>
    </el-dialog>
</template>

<script>

import moment from 'moment'
// import dkelpagination from '@/components/dkElPagination.vue'

let detectBox = {
    template: `
    <g @click="selectBox">
        <rect :x="x" :y="y" :style="[boxStyle1]" :width="width" :height="height" ry="0" rx="0"></rect>
        <rect :x="x" :y="y+height+10" :style="[boxStyle2]" :width="width" height="20" ry="0" rx="0"></rect>
        <text :x="x+5" :y="y+height+20" :style="[fontStyle]">{{detectData.carNum}}</text>
    </g>
    `,
    mounted() {
        this.x = Math.ceil(this.rect.left * this.ratio);
        this.y = Math.ceil(this.rect.top * this.ratio);
        this.width = Math.ceil((this.rect.right * this.ratio) - (this.rect.left * this.ratio));
        this.height = Math.ceil((this.rect.bottom * this.ratio) - (this.rect.top * this.ratio)); 
    },
    computed: {
        boxStyle1() {
            const style = {
                fill:'none',
                stroke: (this.selected)?'#5b85c3' :'#efefef',
                strokeWidth: '10px',
                strokeOpacity: '0.8',
                cursor:'pointer'
            }
            return style;
        },
        boxStyle2() {            
            const style = {
                fill:(this.selected)?'#5b85c3' :'#efefef',
                stroke: (this.selected)?'#5b85c3' :'#efefef',
                strokeWidth: '10px',
                opacity: '0.8',
                cursor:'pointer'
            }
            return style;
        },
        fontStyle() {
            const style = {
                font:'bold 20px NanumGothic',
                fill:'#000',
                cursor:'pointer'
            }
            return style;
        }
    },
    data() {
        return {
            x:0,
            y:0,
            width:0,
            height:0,
            label:"",
            limit: 12,
            totalDocs: 0,   
            selected: false,
        }
    },    
    props: {        
        rect: {
            type: Object,
            default: {
                top:0,
                left:0,
                right:0,
                bottom:0
            }
        },
        ratio: {
            type:Number
        },
        detectData: {
            type: Object,
            default: {
                detectId: "",
                carNum: ""
            }
        }
    },
    watch: {
        ratio(val) {            
            this.x = Math.ceil(this.rect.left * this.ratio);
            this.y = Math.ceil(this.rect.top * this.ratio);
            this.width = Math.ceil((this.rect.right * this.ratio) - (this.rect.left * this.ratio));
            this.height = Math.ceil((this.rect.bottom * this.ratio) - (this.rect.top * this.ratio));             
        },
        rect(val) {
          this.x = Math.ceil(this.rect.left * this.ratio);
          this.y = Math.ceil(this.rect.top * this.ratio);
          this.width = Math.ceil((this.rect.right * this.ratio) - (this.rect.left * this.ratio));
          this.height = Math.ceil((this.rect.bottom * this.ratio) - (this.rect.top * this.ratio)); 
          this.$forceUpdate()
        }
    },
    methods: {
        selectBox() {
            this.selected = true;
            this.$parent.$emit('select-box', this);               
        },
        deactivate() {            
            this.selected = false;
            this.$forceUpdate();
        },
        getDetectId() {
            return this.detectData.detectId;
        },
        getDetectData() {
            return this.detectData;
        }
    }
}

export default {
    components: {
        'detect-box': detectBox
    },
    created() {
        
    },    
    props: {
        fcltData: {
            type:Object,
            default: {}
        }
    },
    data() {
        return {
            limit: 12,
            totalDocs: 0,
            liveDetectDialog: false,
            detectCanvasWidth:"0px",
            detectCanvasHeight:"0px",
            detectCanvasViewBox:"0 0 0 0",
            ratio:1,
            selectedBox:null,
            detectData: [],
            refleshTimer: null,
            selectedDetectData: {
                detectId:"",
                carNum:""
            },
            nowPresetDetectData: {
                presetNo:0,
                progressImageInfo:[]
            }
        }
    },    
    computed: {
       
    },
    methods: {
        showDialog() {
            this.liveDetectDialog = true;
        },
        hideDialog() {
            this.liveDetectDialog = false;
            clearInterval(this.refleshTimer);
            this.detectData= [];            
            this.selectedDetectData= {
                detectId:"",
                carNum:"",
                progressImageInfo:[]
            }
        },
        openDialog() {      
            this.$nextTick(()=> {
                let width =  this.$refs.detectCanvasWrap.$el.clientWidth;
                let height = Math.ceil(width / 16) * 9;
                this.detectCanvasWidth = width + "px";
                this.detectCanvasHeight = height + "px";
                this.detectCanvasViewBox = `0 0 ${width} ${height}`;
                this.ratio = width / 1920;

                this.$refs['detectCanvasWrap'].$on('select-box', (box) => {
                    if (this.selectedBox) {
                        if (box.getDetectId() != this.selectedBox.getDetectId()) {
                            this.selectedBox.deactivate();   
                        }                        
                    }
                    this.selectedBox = box;
                    this.selectedDetectData = this.selectedBox.getDetectData();
                });
                this.getDetectData();                
                this.refleshTimer = setInterval(()=> {
                    this.getDetectData();
                }, 5000)
            })      
        },
        selectRow(row) {            
            // this.selectedDetectData = row;
            this.nowPresetDetectData = row;
        },
        pagingProc() {
            this.getDetectData()
        },
        getDetectData() {
            // let param = {
            //     page: this.$refs.pagination.getPage(),
            //     limit: this.limit,
            //     cameraId : this.fcltData.fcltTypeData.cameraId.value
            // }
            let param = {
              cameraId : this.fcltData.fcltTypeData.cameraId.value
            }
            this.$http.get(`${this.$config.getServerConfig().ipes.api}/ipes/api/recentDetectData`, {params:param})
            .then((result)=> {
                if (result.data.docs.length > 0){
                  if ((this.detectData[0])
                     && this.detectData[0].detectId !== result.data.docs[0].detectId) {
                    this.nowPresetDetectData = result.data.docs[0];
                  }
                  else if (!this.detectData[0]) {
                    this.nowPresetDetectData = result.data.docs[0];
                  }
                  this.totalDocs = result.data.totalDocs || 0;
                  this.detectData = result.data.docs;
                }
                else {
                    this.nowPresetDetectData = {
                        presetNo:0,
                        progressImageInfo:[]
                    }
                }
            })          
        },
        getLeadTime(row) {
            let first = moment(row.firstDetectTime).unix();
            let last = moment(row.nextDetectTime).unix();
            let diff = last - first;
            return `${parseInt(diff/60)}분 ${parseInt(diff%60)}초`;
        }
    }
}
</script>

<style scoped>
.detect-data-table img{
    width:100%;
    height:auto;
}

.detect-canvas {
    width:100%;
    height:auto;    
}

.detect-canvas-wrap {
    background-color:#000;
}

@keyframes blink {
    from {opacity:0;}
    40% {opacity:1;}
    70%  {opacity:0.6;}
    to {opacity:1;}
}

@-webkit-keyframes blink {
    from {opacity:0;}
    40% {opacity:1;}
    70%  {opacity:0.6;}
    to {opacity:1;}
}

.detect-box {
    animation:blink 500ms;
    -webkit-animation:blink 500ms;
}

.detect-data-wrap {
  position:absolute;
  top:60px;
  bottom:0;
  left:0;
  right:0;
  overflow-x:hidden;
  overflow-y:auto;
}
</style>