var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside",
      attrs: {
        title: "실시간 단속 상태",
        visible: _vm.liveDetectDialog,
        "close-on-click-modal": false,
        "append-to-body": "",
        width: "75%",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.liveDetectDialog = $event
        },
        open: _vm.openDialog
      }
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-container",
            [
              _c(
                "el-main",
                { ref: "detectCanvasWrap", staticClass: "detect-canvas-wrap" },
                [
                  _c("div", [
                    _c(
                      "svg",
                      {
                        staticClass: "detect-canvas",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          stroke: "none",
                          fill: "none",
                          overflow: "hidden",
                          preserveAspectRatio: "none",
                          width: _vm.detectCanvasWidth,
                          height: _vm.detectCanvasHeight,
                          viewBox: _vm.detectCanvasViewBox
                        }
                      },
                      [
                        _c("g", [
                          _vm.nowPresetDetectData.progressImageInfo.length > 0
                            ? _c("image", {
                                attrs: {
                                  href:
                                    _vm.nowPresetDetectData.progressImageInfo[0]
                                      .snapshotImageUrl,
                                  width: _vm.detectCanvasWidth,
                                  height: _vm.detectCanvasHeight
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm.nowPresetDetectData.carRect
                          ? _c("detect-box", {
                              staticClass: "detect-box",
                              attrs: {
                                rect: _vm.nowPresetDetectData.carRect,
                                ratio: _vm.ratio,
                                detectData: _vm.nowPresetDetectData
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-aside",
            {
              staticClass: "info-panel",
              staticStyle: { position: "relative" }
            },
            [
              _c("div", [
                _c(
                  "h4",
                  {
                    staticStyle: {
                      margin: "10px 0 0 15px",
                      padding: "0",
                      height: "22px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.fcltData.fcltName))]
                ),
                _c(
                  "h5",
                  {
                    staticStyle: {
                      margin: "5px 10px 0 0",
                      padding: "0",
                      height: "22px",
                      "text-align": "right"
                    }
                  },
                  [_vm._v("단속건수: " + _vm._s(_vm.detectData.length))]
                )
              ]),
              _c(
                "div",
                { staticClass: "detect-data-wrap" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.detectData,
                        "empty-text": "단속 정보가 없습니다.",
                        size: "mini",
                        stripe: ""
                      },
                      on: { "row-click": _vm.selectRow }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "carNum", label: "차량번호" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "경과시간" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.getLeadTime(scope.row)) +
                                    "\n                        "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "상태" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.detectProgressCode == "1"
                                  ? _c("span", [_vm._v("초도")])
                                  : scope.row.detectProgressCode == "3"
                                  ? _c("span", [_vm._v("확정")])
                                  : scope.row.detectProgressCode == "99"
                                  ? _c("span", [_vm._v("실패")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }